* {
  color: $text-color;
}

.color-light {
  color: $text-color;
}

.color-dark {
  color: $dark-color;
}

.black-color {
  color: $black-shade !important;
}

.h-txt {
  color: $history-txt;
}

.chart-inner-color {
  color: #909090;
}

.lable14 {
  font-size: 14px;
}

.color-s {
  color: $secondary-color;
}

.bg-color-s {
  background-color: $secondary-color;
}

.textarea {
  resize: none !important;
}

.color-p {
  color: $primary-color;
}

.bg-color-p {
  background-color: $primary-color;
}

a {
  color: $link-color;
  text-decoration: none;
}

.title {
  color: $black-title-shade;
}
.title-1 {
  color: $black-shade;
}

.bg-color-gray {
  background-color: $light-gray-shade;
}

.only_bottom_border {
  border-top: none;
  border-left: none;
  border-right: none;
}

.only_top_border {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.no_border {
  border: none !important;
}

.more_height {
  height: 70vh;
}

.cardImg {
  width: 30px;
  height: 30px;
}

.b-fn {
  color: $black-title-shade;
}

.faded-title {
  color: $epic-win;
}

.text-left {
  width: 122px;
}

.tx-s {
  // width: 10px;
  // display: flex;
  // justify-content: left;
}

.sg-up-n {
  margin-top: -10px !important;

  .form-check-input {
    margin-right: 5px !important;
    box-shadow: none !important;
    border-color: $primary-color;
  }

  .form-check-input:checked[type="checkbox"] {
    background-image: url(../../assets/checkBox.svg);
    background-color: $white-shade;
    border-color: $primary-color;
  }
}

/* Fonts */

.f16 {
  font-size: 16px !important;
}

.f14 {
  font-size: 14px !important;
}

.f12 {
  font-size: 12px !important;
}

.f18 {
  font-size: 18px !important;
}

.f20 {
  font-size: 20px !important;
}

.f22 {
  font-size: 22px !important;
}

.f26 {
  font-size: 26px !important;
}

.f24 {
  font-size: 24px !important;
}

.f30 {
  font-size: 30px !important;
}

.f40 {
  font-size: 40px !important;
}

/*margins*/

.mt36 {
  margin-top: 36px;
}

.pr-r {
  padding-right: 57px !important;
}

.mb36 {
  margin-bottom: 36px;
}

.mt150 {
  margin-top: 150px;
}

.pu-btn {
  width: 94px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 42px !important;
  margin-top: 10px !important;

  padding-top: 9px !important;
}

.pu-active-btn {
  box-shadow: 1px 3px 0px 0.75px #e7e7e7 !important;
}

/* border-radius */

.bd-rd10 {
  border-radius: 10px !important;
}

.bd-n {
  border: none;
}

.img {
  border-radius: 50px !important;
}

/* button */

.pu-btn-m {
  width: 166px !important;
  height: 52px !important;
}

.pu-btn-f {
  width: 168px !important;
}

.btn-i80 {
  width: 180px !important;
}

.dfl-bt {
  width: 178px !important;
  height: 52px !important;
}

.lr-btn {
  width: 256px !important;
  height: 52px !important;
}

.lr-btn-wee {
  width: 230px !important;
  height: 52px !important;
}

.pu-crd {
  width: 210px !important;
  height: 210px !important;
}

.pop-bt {
  width: 144px !important;
  height: 48px !important;
}

.r-Dt-c {
  color: #666666 !important;
}

.cn-bt {
  width: 216.46px !important;
  height: 62.54px !important;
  font-weight: 600 !important;
  font-size: 21px !important;
  line-height: 30px !important;
}

.c-ss {
  color: #555555 !important;
}
.c-gts {
  color: #444444 !important;
}

/*opacity*/

.op-c {
  opacity: 0.6;
}

.active-cl {
  max-width: 600px !important;
}

/*table*/

.cl-table {
  border: 1px solid #f2f2f2;
  max-width: 450px !important;

  .row-br {
    border-bottom: 1px solid #f2f2f2 !important;
  }

  .list-group-item {
    border: 0;
  }
}

.lt-gr-it {
  border-bottom: none;
}

.grey-btn-cl {
  color: $black-shade;
  opacity: 0.6;
  background-color: #eeeeee;
}

.plus-card-icon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*spacing*/
.act-mr30 {
  margin-right: 30px;
}

.divd-his {
  opacity: 0.08;
  color: #000000;
}

.mar-p {
  margin-left: -22px;
}
.mar-ps {
  margin-left: -26px;
}
.ex-s {
  margin-left: -20px;
}

.backArr-mt-min {
  margin-top: -1px !important;
}

.sec-bd {
  background: white;
  border-radius: 10px;
  position: absolute;
  margin-top: 31px !important;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 0px;
  .modal-content {
    width: 90% !important;
  }
  .marg-pop {
    margin-left: 68px;
    margin-top: -11px !important;
    width: 200px;
    line-height: 120% !important;
  }
}

.sp-win {
  margin-right: 30px;
}

.lowerBorder {
  border-bottom: 1px solid $border-text;
}

.notification-pg {
  li {
    &.no-read {
      font-weight: 400;
      font-size: 12.2457px;
      line-height: 1;
      color: #666666;
      position: relative;
      display: flex;
      align-items: center;
      padding-top: 2px;

      &::before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        background-color: $primary-color;
      }
    }
    &.read {
      list-style-type: none;
    }
  }
}

.notification-bg {
  position: absolute;
  right: 52px;
  top: 22px;
}

.dis-for {
  .all-br {
    .form-control:disabled {
      border: 1px solid #f4f4f4 !important;
    }
  }
  .right-check {
    .form-control:disabled {
      border-right: none !important;
      border: 1px solid #f4f4f4 !important;
    }
  }
}

.feed-hd {
  text-align: left !important;
  margin-left: 96px;
}

.weekly-text {
  color: $black-shade !important;
  opacity: 0.6 !important;
}

.border-bottom-1 {
  border-bottom: 1px solid hsla(0, 0%, 90%, 1);
}

.wh-pre-wrap {
  white-space: pre-wrap;
}
