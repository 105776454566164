.tab-container {
  a.selected {
    color: $secondary-color;
    border-bottom: 3px solid $secondary-color;
  }
  .discover-class {
    margin-left: -16px;
  }
  .dis-spces {
    margin-right: 50px;
  }
}
