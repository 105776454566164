.quiz-container {
  display: flex;
  flex-direction: column;

  main {
    display: flex;
  }
  .quiz-mark {
    display: flex;
    align-items: center;
    padding-bottom: 14px;
    margin-right: 64px;
    border-radius: 10px;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.03);
  }
  .card-img-s {
    width: 72px !important;
  }
  .indexedStep {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    background-color: #f2f2f2;
    border-radius: 20px;
    margin-right: 0;
    // margin-bottom: 5px;
    cursor: pointer;
  }
  .indexedStep.accomplished {
    background-color: #f5ab38;
  }
  .RSPBprogressBar {
    height: 5px;
  }

  .previous {
    width: 20px;
    color: #4a60ab;
  }
  .previous:hover {
    color: #4a60ab;
  }
  .questiontext {
    font-size: 20px;
    margin-bottom: 18px;
    color: #121212;
  }
  .progress {
    height: 8px !important;
  }
}

.c-qz {
  .quiz-box-h {
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #f2f2f2;
  }
  .quiz-box-f {
    padding: 10px;
    border-bottom: 1px solid #f2f2f2;
  }
  .quiz-box-nb {
    padding-bottom: 10px;
    padding-left: 10px;
  }
  .p-box-h {
    padding-bottom: 8px;
    border-bottom: 1px solid #f2f2f2;
  }
  .p-box-nb {
  }
  .list-group-item {
    border-bottom: none;
    border-color: #f2f2f2 !important;
  }
  .br-btm {
    border-bottom: 1px solid #f2f2f2;
  }
}
