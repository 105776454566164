.select-account-conatiner {
  height: 100vh;
  background: #ffffff00;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  .main-heading {
    font-size: 26px;
    font-weight: 600;
    line-height: 37px;
    letter-spacing: 0px;
    text-align: center;
    color: $primary-color;
    margin-top: 40px;
  }

  .card {
    width: 26.3rem;
    height: 32.5rem;
    padding: 18px;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.05);
    border: none;
    margin-top: 40px;
    margin-right: 60px;
  }
  .cc-d {
    &.card {
      width: 26.3rem;
      height: 32.5rem;
      padding: 18px;
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.05);
      border: 1px solid $white-shade-1;
      margin-top: 40px;
      margin-right: 0px;

      &.hero {
        border-color: $primary-color;
      }
    }
  }

  .card-top {
    background-color: $light-skin;
    width: 24rem;
    height: 15.6rem;
    border-radius: 8px;
  }

  .top-button {
    background-color: $avatar-border !important;
    padding: 8px 24px;
    height: 42px;
    border: none;

    &.default-btn {
      width: auto !important;
    }
  }

  .top-card-heading {
    font-family: Source Sans 3;
    font-size: 60px;
    font-weight: 700;
    line-height: 85px;
    letter-spacing: 3px;
    text-align: center;
    color: $primary-color;
    margin-bottom: 0;
    text-shadow: 2px 2px 0px #4a60ab5c !important;
  }

  .lower-heading {
    font-family: Source Sans 3;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 1px;
    text-align: left;
    margin-top: -10px;
  }

  .card-lower-button {
    position: absolute;
    bottom: 0px;
    margin-left: 6.5rem;
    margin-bottom: 40px;

    .dfl-bt.free-trial {
      background-color: $white-shade;
      border-color: $primary-color;
      color: $primary-color;
      &.player {
        border-color: $gray-1;
        color: $gray-1;
      }

      &:hover {
        background-color: $white-shade !important;
        opacity: 0.7;
      }
    }
  }

  .card-Play {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 12px;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.05);
    margin-bottom: 32px;
    border-radius: 12px;
    border: 1px solid #f7f7f7;
  }

  .how-to-logo {
    position: absolute;
    right: 0px;
  }
  .sec {
    height: 10px;
  }

  .right-padding {
    padding-right: 100px;
  }

  .top-section {
    margin-top: 40px;
  }

  .how-pic {
    width: 440px;
    height: 230px;
  }

  .how-play-bt {
    width: 90px;
    background-color: $avatar-border;
    border: none;
    margin-right: -15px;
    z-index: 99999;
    &:hover {
      background-color: $avatar-border !important;
    }
  }

  .button-sec {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 3rem;
    padding-top: 1rem;
  }
}
