.default-btn {
  // background-color: $;
  border-radius: 3rem;
  height: 3rem;
  width: 12rem;
  border: 1px solid $primary-color;
}

.default-btn:hover {
  background-color: $primary-color-dark !important;
  border: 1px solid $primary-color-dark;
}

.grey-btn {
  background-color: $palette-shade;
  color: $black-shade;
  border: none;

  &:hover {
    background-color: $palette-shade !important;
    color: $black-shade !important;
    border: none !important;
  }
  // border: 1px solid $primary-color-dark;
}

.round-btn {
  @include round(2rem);
  display: grid;
  place-items: center;
}

@for $round-index from 1 to 4 {
  .round-btn-#{$round-index} {
    @include round($round-index);
    display: grid;
    place-items: center;
  }
}

.ac-bdr {
  border: 1px solid $primary-color !important;
  color: $primary-color !important;
}

.back-button-1 {
  background-color: $cancel-shade;
  color: $black-shade;
  border: none;
  display: block;
  position: absolute;
  padding: 0;
  width: 8rem;
  bottom: 1rem;
  &:hover {
    background-color: $cancel-shade !important;
    border: none;
  }
}
