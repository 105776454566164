.discover-container {
  margin: 0;
  padding: 2rem;
  background-image: url("../../assets/auth-landing-curves-2.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-attachment: fixed;

  .user-avatar {
    border-radius: 50%;
    object-fit: cover;
    width: 70px;
    height: 70px;
  }

  .avatar-edit {
    position: absolute;
    top: 0;
    right: -14px;
    z-index: 10;
  }
}
