.weekday-container {
  width: 70%;
  .round-btn.schedule {
    @include round(3.5rem);
    position: relative;
    background-color: $white-shade;
    color: $text-color;
    border: 1px solid $palette-shade;

    &:hover {
      background-color: $white-shade !important;
      color: $text-color;
      border: 1px solid $palette-shade;
    }

    &.active {
      background-color: $primary-color;
      color: $white-shade;
      border: 1px solid $primary-color;

      &:hover {
        background-color: $primary-color !important;
        color: $white-shade !important;
        border: 1px solid $primary-color;
      }
    }
    .ops {
      opacity: 8;
    }
  }

  .col:not(:last-child) .round-btn.schedule::after {
    content: "|";
    color: $palette-shade;
    position: absolute;
    right: -2rem;
    font-size: 2rem;
  }
}
