.main {
  display: flex;
  flex-direction: column;

  .first-div {
    display: flex;

    &-score {
      padding: 1rem;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 30%;
      background: #ffffff;
      border: 1px solid #f9f9f9;
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.05);
      border-radius: 8px;

      span {
        margin-bottom: 25px;
      }
    }
    &-strength {
      padding-left: 2rem;
      padding-right: 3rem;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 65%;

      .top {
        width: 100%;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .stripe {
        width: 100%;
        .game-ch {
          .cl-game {
            width: 59% !important;
          }
          .cl-game2 {
            width: 5px !important;
          }
        }
        .progress {
          height: 8px !important;
          max-width: 360px !important;
        }
        .title {
          font-size: 14px;
        }
        .qz-di {
          opacity: 0.06;
          color: #000000;
          background-color: #000000;
          margin-top: 12px !important;
        }
        .m-bt-qz {
          margin-bottom: -2px !important;
        }
      }
    }
  }
}

.bar {
  position: relative;
  &::after {
    content: " ";
    position: absolute;
    background: url("../../assets/border-dotted.png") no-repeat;
    width: 62%;
    height: 62%;
    top: 50%;
    left: 50%;
    transform: translate(-53%, -51%);
    background-size: contain;
  }
}

.loading {
  display: flex;
  justify-content: center;
}
