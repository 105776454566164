.power-container {
  &.main {
    width: 100%;
    height: 100%;
    .powerIcon {
      margin-top: 49px;
      display: flex;
      justify-content: center;
      margin-bottom: 36px;
      z-index: 999;
    }

    .index {
      z-index: 999;
    }

    .power-main-heading {
      font-weight: 600;
      font-size: 30px;
      line-height: 43px;
      margin-bottom: 5px;
      margin-top: -39px;
      color: $black-shade;
      z-index: 999;
    }

    .detail_title {
      color: $black-shade;
      display: inline-block;
    }
    .detail_underline {
      color: $yellow-dark-shade;
      border: 7px solid;
      border-radius: 5px;
      margin-top: -11px;
    }

    .underline {
      position: absolute;
      z-index: 999;
    }

    .middle-inner {
      margin-bottom: 26px;
      z-index: 999;
    }

    .middle-inner-lo {
      margin-bottom: 26px;
      z-index: 999;
      margin-top: -15px;
    }

    .power-heading {
      font-weight: 600;
      font-size: 30px;
      line-height: 43px;
      color: $black-shade;
      z-index: 999;
      // border-bottom: 12px solid $yellow-dark-shade;
    }

    .plike {
      margin-right: 6px;
      z-index: 999;
    }
    img.cursor.plike:active {
      transform: scale(1.15);
    }
    img.cursor.dlike:active {
      transform: scale(1.15);
    }

    .power-activated {
      background-color: $primary-color;
      z-index: 999;
      &:hover {
        background-color: $primary-color !important;
      }
    }
    .lowerPower {
      margin-top: 20px;
      z-index: 999;
      color: $black-title-shade;
      text-align: center;
      cursor: pointer;
    }
  }
  .opaque-mask {
    width: 100%;
    position: absolute;
    opacity: 0.95;
    z-index: 1;
    margin-top: 60px;
    height: calc(100vh - 76px);
    background-color: $yellow-shade;
    position: fixed;
    //   @media screen and ( max-height: 950px ) {
    //     height: 132%;
    // }
  }

  &.bg {
    background-image: url("../../assets/yellow.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  &.feedback {
    .feedback-heading-week {
      margin-right: 45px !important;
      margin-bottom: 30px !important;
    }
    .feedback-heading {
      font-weight: 600;
      font-size: 30px;
      line-height: 43px;
      margin-bottom: 40px;
      color: $primary-color;
      margin-top: 42px;
    }
    .two-logo {
      margin-right: 12rem;
    }
    .quest-feedback-heading {
      font-weight: 600;
      font-size: 30px;
      line-height: 43px;
      margin-right: 30px;
      margin-bottom: 40px;
      color: $primary-color;
      margin-top: 42px;
      text-align: center;
    }
    .spacing-left {
      margin-left: 60px;
    }
    .Gaurd {
      margin-top: -3.6rem;
      margin-left: 6rem;
      margin-bottom: 12px;
    }
    .top-icon {
      margin-left: 55px;
    }
    .physical-resilience {
      background-color: $phy-guard;
      border: 1px solid $phy-text !important;
      color: $phy-text;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      padding: 6px 7px;
      height: 36px;
      margin-bottom: 36px;
      width: 170px;
      margin-top: 18px;
      margin-right: 10px;
      &:hover {
        background-color: $phy-guard !important;
      }
    }
    .text-heading {
      font-size: 30px;
      font-weight: 600;
      line-height: 42px;
      color: $black-shade;
      text-align: center;
      margin-bottom: 14px;
    }

    .text-detail {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0px;
      text-align: center;
      color: $black-shade;
    }
    .form-text-detail {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0px;
      text-align: center;
    }
    .lower-button {
      margin-top: 40px;
      margin-right: 12px;
    }
    .quest-lower-button {
      margin-top: 20px;
      margin-right: 12px;
    }
  }
}
.notesContainer {
  &.notes {
    .notes-heading {
      font-weight: 600;
      font-size: 26px;
      line-height: 37px;
      margin-bottom: 40px;
      color: $black-shade;
      margin-top: 42px;
      text-align: center;
    }
    .notes-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color: $black-shade;
    }
    .noteError {
      color: red;
    }
    .counting {
      margin-top: -28px;
      margin-left: 36rem;
      position: absolute;
      color: $black-shade;
      opacity: 0.2;
    }
  }
  .card {
    border: none;
    box-shadow: 0px 7px 8px $shadow-color;
    max-width: 600px;
  }

  .card-header {
    background-color: $white-shade;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $black-shade;
  }
  .margins {
    margin-top: 2rem;
  }
}

.b-img {
  position: absolute;
  bottom: 0;
  right: 0;
}

.social-resilience {
  background-color: $yellow-light-shade;
  border: 1px solid $yellow-botton-text !important;
  color: $yellow-botton-text;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding: 6px 22px;
  height: 36px;
  margin-bottom: 36px;
  margin-top: 18px;
  width: 170px;
  &:hover {
    background-color: $yellow-light-shade !important;
  }
}

.heart-resilience {
  background-color: $bg-heart;
  border: 1px solid $heart-text !important;
  color: $heart-text;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding: 6px 7px;
  height: 36px;
  margin-bottom: 36px;
  width: 170px;
  margin-top: 18px;
  margin-right: 10px;
  &:hover {
    background-color: $bg-heart !important;
  }
}

.physical-resilience {
  background-color: $phy-guard;
  border: 1px solid $phy-text !important;
  color: $phy-text;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding: 6px 7px;
  height: 36px;
  margin-bottom: 36px;
  width: 170px;
  margin-top: 18px;
  margin-right: 10px;
  &:hover {
    background-color: $phy-guard !important;
  }
}
.bonus-resilience {
  background-color: $bonus-guard;
  border: 1px solid $dark-purple-color !important;
  color: $dark-purple-color;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding: 6px 7px;
  height: 36px;
  margin-bottom: 36px;
  width: 170px;
  margin-top: 18px;
  margin-right: 10px;
  &:hover {
    background-color: $bonus-guard !important;
  }
}

.bad-resilience {
  background-color: $purple-light-shade;
  border: 1px solid $purple-border-shade !important;
  color: $purple-border-shade;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding: 6px 22px;
  height: 36px;
  margin-bottom: 36px;
  margin-top: 18px;
  width: 170px;

  &:hover {
    background-color: $purple-light-shade !important;
  }
}

.feedback-No {
  margin-top: -3.9rem;
  margin-left: 4.7rem;
  width: 2px;
  color: $white-shade;
  font-size: 22px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;
}
.bonus-text {
  transform: translate(-2rem, -6.2rem);
  position: absolute;
  color: $white-shade;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
}

.mr15 {
  margin-right: 15px;
}
