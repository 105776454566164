.user-card {
  border: none;
  width: 30vw;
  max-width: 2000px;
  display: block;

  .icon-w {
    width: 74px !important;
  }

  .left-pl-br {
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
  }

  .user-avatar {
    width: 4.93rem;
  }

  .bottom-border {
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
  }

  .mr-star {
    margin-right: 30px !important;
  }

  .analytics-text {
    width: 70%;
    margin: 0 auto;
  }

  .total_width {
    width: 50%;
  }

  .first_para {
    margin-right: 10px;
  }

  .quizCard-container {
    background: $white-shade !important;
    border: 1px solid $light-prefrence !important;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.02) !important;
    border-radius: 8px !important;
  }
}

.content-container {
  // padding: 0 10rem 0 0;

  &.quiz {
    .list-group {
      width: 30rem;
    }

    .list {
      border-radius: 10px;
      border: 1.5px solid #eaeaea;
    }

    .selectedAns {
      border-radius: 10px;
      border: 1.5px solid $primary-color;
    }
  }

  &.settings {
    .settings-card {
      box-shadow: 6px 6px 12px $shadow-color;
      border: none;
      cursor: pointer;
      width: 70%;
    }

    @include media-breakpoint-up(md) {
      .settings-card {
        width: 100%;
      }
    }

    @include media-breakpoint-up(lg) {
      .settings-card {
        width: 100%;
      }
    }

    @include media-breakpoint-up(xl) {
      .settings-card {
        width: 70%;
      }
    }

    .setting-lower-section {
      max-width: 10.5rem;
      margin-right: 14px;
    }

    &.profile {
      .form-control {
        color: $text-color;
      }

      .form-select {
        color: $text-color;
      }
    }

    &.contact {
      .top-contact {
        color: $primary-color;
        margin-top: 20px;
      }

      .contact-email {
        color: $avatar-border;
        opacity: 0.6;
      }
    }

    &.subscription {
      .subscription-card {
        background: $white-shade;
        border: 1px solid $light-prefrence;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        max-width: 32rem;
        min-height: 9rem;
      }

      .subscription-card-wh {
        background: $white-shade;
        border: 1px solid $light-prefrence;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        max-width: 32rem;
      }

      .cancel-card {
        background: $white-shade;
        border: 1px solid $light-prefrence;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        max-width: 32rem;
        min-height: 7rem;
      }

      .main-title {
        width: 21rem;
      }

      .main-Hero {
        width: 28.3rem;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
      }

      .subscription-Cancelled {
        margin-top: -11px;
      }

      .end-paln {
        margin-top: -10px;
        margin-bottom: 60px;
      }

      .right-title {
        height: 30px;
        color: $avatar-border;
      }

      .link-button {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: #4a60ab;
        opacity: 0.9;
        position: absolute;
        bottom: 10px;
        right: 40px;
        cursor: pointer;
      }

      .bottom-margin {
        margin-bottom: 70px;
      }
    }

    &.reminder {
      .reminder-card {
        background: $white-shade;
        border: 1px solid $light-prefrence;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        max-width: 29rem;

        .form-control {
          font-size: 24px !important;
          font-weight: 600;
        }

        .card-body {
          padding: 0px !important;
          padding-right: 10px !important;
        }
      }

      .timer-css {
        border: none;
      }
    }

    &.prefrences {
      .prefrence-card {
        background: $white-shade;
        border: 1px solid $light-prefrence;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        max-width: 29rem;
      }

      .form-check-input {
        height: 21px;
        margin-left: 2px;
        width: 32px;
        left: 922px;
        top: 277px;
        border-radius: 20px;
      }
    }

    &.changePassword {
      .form-control {
        color: $text-color;
        border-right: none;
      }
    }
  }

  .edit-logo-date {
    display: flex;
    position: absolute;
  }

  .birthDate-aligment {
    display: flex;
    justify-content: flex-end;
  }

  .date-pencile {
    margin-top: 10px;
    margin-right: 10px;
  }

  .backArrow {
    margin-left: -25px;
    cursor: pointer;
    margin-bottom: 1px;
  }

  .profile-cancel {
    background-color: $cancel-shade;
    border: 1px solid $cancel-shade;
    color: $black-shade;
    margin-left: 20px;

    &:hover {
      background-color: $cancel-shade !important;
      border: 1px solid $cancel-shade !important;
      color: $black-shade;
    }
  }

  &.science-behind {
    height: 100vh;
    position: relative;

    p {
      line-height: 1.2rem;
    }

    .bc-con {
      position: absolute;
      left: 0;
      padding-bottom: 20px;
    }

    .back-btn {
      width: 6rem;
      margin-top: 35px;

      &:hover {
        background-color: inherit;
      }
    }
  }

  &.discover {
    .squad-invitation {
      border: 1px solid #f5ab38 !important;
      border-width: 0;
      border-radius: 10px;
      box-shadow: none !important;

      .squad-name {
        font-weight: 600;
        font-size: 14px;
        color: #f99f22;
        opacity: 0.9;
        margin-bottom: 1px;
      }

      .invitation-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #121212;
        margin-bottom: 0;
      }
    }

    .pd-Squad-c {
      padding-left: 10px;
    }

    .discover-circle {
      height: 66px;
      width: 66px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .circle-Sb {
      color: $white-shade;
      font-weight: 600;
      font-size: 24px;
    }

    .no_invitation {
      height: 60vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .loading {
      display: flex;
      justify-content: center;
      margin-right: 10rem;
    }

    .discover-card-btn {
      @include round(2.5rem);

      img {
        height: 0.6rem;
        width: auto;
      }

      border: none;

      &.play-btn {
        @include round(3rem);
        border: 5px solid $primary-color-light;

        img {
          height: 0.9rem;
          width: auto;
        }
      }

      &.check {
        background-color: #99c815;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover,
        &:focus {
          background-color: #99c815 !important;
          color: white !important;
          border: none !important;
        }
      }

      &.cancel {
        background-color: #dcdcdc;
        color: #121212;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover,
        &:focus {
          background-color: #dcdcdc !important;
          color: #121212 !important;
        }
      }
    }

    .colorful-card {
      border: 1px solid #f9f9f9;
      background: $white-shade;
      border-radius: 10px;
      cursor: pointer;
    }

    .cards-m {
      margin-top: -1px;
    }
  }

  &.squad-play {
    .medal-card {
      border: none;
      box-shadow: 0px 7px 8px $shadow-color;
    }

    .no_invitation {
      height: 55vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .see-invitaion {
      text-decoration: none;
      color: $primary-color;
    }

    .loading {
      display: flex;
      justify-content: center;
      margin-right: 10rem;
    }

    .squad-profile-pic {
      width: 70px;
      height: 70px;
      border-radius: 50px;
    }

    .without-img {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $avatar-border;
    }

    .medal-container {
      gap: 0.5em;

      img {
        @include round(2rem);
      }

      img.used {
        opacity: 0.5;
      }
    }

    .Activity-title {
      font-size: 22px;
      font-weight: 500;
      line-height: 31px;
      letter-spacing: 0px;
      text-align: left;
      color: $black-shade;
    }

    .wrap {
      display: flex !important;
      flex-wrap: inherit;
    }
  }

  &.invitations {
    .card {
      box-shadow: 0px 8px 8px $shadow-color;
      border: none;
    }
  }
}

.science-behind-card {
  cursor: pointer;

  &:hover,
  &.active {
    background-color: $primary-color;

    .title {
      color: $white-shade;
    }
    .title-1 {
      color: $white-shade;
    }

    button {
      background-color: $white-shade !important;

      svg {
        path {
          stroke: $primary-color !important;
        }
      }
    }
  }
}

.filters-discover {
  .dropdown-toggle {
    background-color: white;
    border: 1px solid #efefef;
    border-radius: 30px;
    color: black;
    padding: 12px 26px;
    margin-right: 25px;

    &:hover,
    &:focus,
    &:active {
      background-color: white;
      border: 1px solid #efefef;
      border-radius: 30px;
      color: black;
    }
  }

  .dropdown-toggle::after {
    display: none !important;
  }

  .dropdown-menu {
    margin-top: 20px;
    margin-top: 20px;
    margin-left: -30px;
    border-radius: 12px;
    padding: 7px 8px;
    border: 1px solid #f1f1f1;
    text-align: left !important;

    .dropdown-divider {
      border: 0.4px solid #000000;
      opacity: 0.08;
      padding: 0;
      margin: 0;
    }

    .ml-history {
      svg {
        position: relative;
        left: -3px;
      }
    }

    .at-f {
      color: $avatar-border !important;
    }

    .dropdown-item {
      border-radius: 12px;
      margin-left: -5px !important;
      text-align: start;
      font-weight: 600;

      .filers-a {
        margin-left: 6px;
        font-weight: 600;
        color: $black-shade;
      }

      .filers-h {
        font-weight: 600;
        color: $black-shade;
      }

      &:hover,
      &:focus,
      &:active {
        background-color: $white-shade;
      }

      svg {
        margin-bottom: 2px;
        margin-left: -5px !important;
        margin-right: 2px !important;
      }
    }

    .dropdown-item:active {
      background-color: $white-shade;
      color: $avatar-border;

      .filers-a {
        color: $avatar-border;
      }

      .filers-h {
        color: $avatar-border;
      }

      svg {
        path {
          stroke: $avatar-border;
        }
      }
    }
  }
}
