.auth-container {
  // overflow-y: hidden;
  @media only screen and (max-width: 500px) {
    overflow-y: auto !important;
  }

  .row {
    height: 100vh;
    align-items: stretch;
  }

  .col {
    margin: 0;
    padding: 0;
  }

  & > .row > .col {
    display: flex;
    // for main-content to be in center
    justify-content: center;
  }

  .landing-container {
    position: fixed;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.03);
    max-width: 50%;

    [class*="col"]:nth-child(1) {
      background-image: url("../../assets/auth-landing-curves-1.svg");
      background-size: cover;
      background-position: -10% 100%;
      background-repeat: no-repeat;
    }

    [class*="col"]:nth-child(2) {
      text-align: center;
      height: 14rem;
      margin-top: 4rem !important;

      img {
        height: 11rem;
        margin: 1rem 0;
      }

      h6 {
        width: 13rem;
        margin: 0 auto;
        margin-top: 30px;
      }
    }

    [class*="col"]:nth-child(3) {
      background-image: url("../../assets/auth-landing-curves-2.svg");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 40%;
    }

    .tagline-color {
      color: #444444;
    }
  }
}

.auth-content-container {
  align-self: center;
  width: 70%;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (max-width: 500px) {
    margin-top: 5rem;
  }

  .content-heading {
    font-weight: 600;
    font-size: 28px;

    span {
      min-width: 2rem;
      min-height: 3px;
      display: inline-block;
      transform: translateY(-0.45rem);
      border-radius: 5px;
    }
  }

  .white-btn-sm {
    background-color: $white-shade;
    width: 5rem;
    border: 1px solid #dddddd;
    margin-right: 0.5rem;
    display: flex;
  }

  .white-btn-sm:hover {
    background-color: $white-shade !important;
    border: 1px solid #dddddd;
  }

  .login-box {
    .form-control {
      border: 1px solid #ebebeb !important;
      border-radius: 6px !important;
    }
  }

  .fo-pss {
    margin-right: -10px !important;
  }

  .login-bt {
    width: 180px !important;
    height: 52px !important;
  }
}

.em-verification {
  margin-bottom: 3rem;
}

.em-l-txt {
  margin-top: 8rem !important;
}

.em-v-txt {
  margin-top: 6rem !important;
}
