.default-card-container {
  div:nth-child(2) {
    justify-content: flex-end;
  }

  .row {
    align-items: center;
  }
}

.upgarde-pop-bg {
  background-color: #ffcc1d;
  &.modal-section-pop {
    text-align: center;
    margin: -5px !important;
    border-radius: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
