.piechart-container {
  position: relative;
  .resilience-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  h5 {
    line-height: 0.5rem;
  }
  p {
    width: 3.5rem;
  }
}

/* donut chart css */

.donutchart-container {
  position: relative;

  .resilience-container {
    position: absolute;
    top: 46%;
    left: 51.5%;
    transform: translate(-52%, -50%);
    text-align: center;
  }

  .data-chart {
    li {
      font-weight: 400;
      font-size: 12.2457px;
      line-height: 1;
      color: #666666;
      position: relative;
      display: flex;
      align-items: center;
      padding-top: 2px;

      &::before {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }
    .Emotional {
      &:before {
        background-color: #cf4476;
      }
    }
    .Physical {
      &:before {
        background-color: #f9a623;
      }
    }
    .Mental {
      &:before {
        background-color: #2844a3;
      }
    }
    .Social {
      &:before {
        background-color: #b9d435;
      }
    }
  }

  .text-pie {
    margin-top: -10px;
    text-align: center;
  }

  .main-title {
    font-weight: bolder;
  }

  .card-title {
    color: #121212;
  }

  h5 {
    line-height: 0.5rem;
  }

  p {
    width: 3.5rem;
  }
}
