.bad-container {
  &.main {
    width: 100%;

    .badIcon {
      display: flex;
      justify-content: center;
      margin-bottom: 36px;
      z-index: 999;
    }

    .form-check-input:checked {
      background-color: #9146a4 !important;
      border-color: #9146a4 !important;
    }

    .index {
      z-index: 999;
    }

    .opaque-mask-Quest {
      width: 100%;
      position: absolute;
      opacity: 0.95;
      z-index: 1;
      height: calc(100vh - 76px);
      background-color: $green-shade;

      // @media screen and (max-height: 950px) {
      //     height: 120%;
      // }

      // @media screen and (max-height: 850px) {
      //     height: 140%;
      // }

      // @media screen and (max-height: 700px) {
      //     height: 170%;
      // }
    }

    .quest-completed {
      background-color: $primary-color;
      z-index: 999;
      color: $white-shade;

      &:hover {
        background-color: $primary-color !important;
        color: $white-shade !important;
      }
    }

    .underline {
      position: absolute;
      z-index: 999;
    }

    .top-inner {
      margin-bottom: 16px;
      z-index: 999;
    }

    .middle-inner {
      margin-bottom: 24px;
      z-index: 999;
    }

    .bad-heading {
      font-weight: 600;
      font-size: 30px;
      line-height: 43px;
      margin-right: 360px;
      color: $white-shade;
      z-index: 999;
      // border-bottom: 12px solid $yellow-dark-shade;
    }

    .bad-main-heading {
      font-weight: 600;
      font-size: 30px;
      line-height: 43px;
      margin-bottom: 26px;
      margin-top: -39px;
      color: $white-shade;
      z-index: 999;
    }

    .battle-heading {
      font-weight: 600;
      font-size: 22px;
      line-height: 31px;
      color: $white-shade;
      z-index: 999;
    }

    .battle-Detail {
      max-width: 760px;
      background-color: $purple-dark-shade;
      border-radius: 14px;
      padding: 26px;
    }

    .plike {
      margin-right: 6px;
      z-index: 999;
    }

    .bad-activated {
      background-color: $white-shade;
      z-index: 999;
      color: $purple-shade;
      width: 150px;
      height: 52px;

      &:hover {
        background-color: $white-shade !important;
        color: $purple-shade !important;
      }
    }

    .lowerbad {
      margin-top: 20px;
      z-index: 999;
      color: $white-shade;
      opacity: 0.75;
      padding-bottom: 30px;
    }

    .lower-inner {
      margin-bottom: 14px;
      z-index: 999;
    }

    .inner-text-area {
      margin-left: -13px;
    }

    .bad-Arrow {
      margin-right: 20px;
    }

    .inner-battle {
      margin-bottom: 12px;
    }

    .opaque-mask {
      width: 100%;
      position: absolute;
      opacity: 0.95;
      z-index: 1;
      height: 100%;
      background-color: $purple-shade;
      position: fixed;

      // @media screen and (max-height: 950px) {
      //     height: 120%;
      // }

      // @media screen and (max-height: 850px) {
      //     height: 140%;
      // }

      // @media screen and (max-height: 700px) {
      //     height: 170%;
      // }
    }
  }

  &.mainPaid {
    width: 100%;
    height: 100%;

    .badIcon {
      display: flex;
      justify-content: center;
      margin-bottom: 36px;
      z-index: 999;
    }

    .index {
      z-index: 999;
    }

    .underline {
      position: absolute;
      z-index: 999;
    }

    .top-inner {
      margin-bottom: 16px;
      z-index: 999;
    }

    .middle-inner {
      margin-bottom: 24px;
      z-index: 1;
    }

    .bad-heading {
      font-weight: 600;
      font-size: 30px;
      line-height: 43px;
      margin-right: 360px;
      color: $white-shade;
      z-index: 999;
      // border-bottom: 12px solid $yellow-dark-shade;
    }

    .bad-main-heading {
      font-weight: 600;
      font-size: 30px;
      line-height: 43px;
      margin-bottom: 26px;
      margin-top: -39px;
      color: $white-shade;
      z-index: 999;
      text-align: center;
    }

    .battle-heading {
      font-weight: 600;
      font-size: 22px;
      line-height: 31px;
      color: $white-shade;
      z-index: 999;
    }

    .battle-Detail {
      max-width: 760px;
      background-color: $very-dark-purple;
      border-radius: 14px;
      padding: 26px;
    }

    .plike {
      margin-right: 6px;
      z-index: 999;
    }

    .bad-activated {
      background-color: $white-shade;
      z-index: 999;
      color: $purple-shade;

      &:hover {
        background-color: $white-shade !important;
        color: $purple-shade !important;
      }
    }

    .lowerbad {
      margin-top: 20px;
      z-index: 999;
      color: $white-shade;
      opacity: 0.75;
      padding-bottom: 30px;
      text-align: center;
    }

    .lower-inner {
      margin-bottom: 14px;
      z-index: 999;
    }

    .bad-Arrow {
      margin-right: 20px;
    }

    .inner-battle {
      margin-bottom: 12px;
    }

    .inner-text-area {
      margin-left: -13px;
    }

    .opaque-mask {
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0.95;
      z-index: 1;
      background-color: $dark-purple-color;
    }
  }

  &.bg {
    background-image: url("../../assets/purple.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  &.bg-paid {
    background-image: url("../../assets/BossBg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  &.bg-q {
    background-image: url("../../assets/Green.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  &.feedback {
    .bad-feedback-heading {
      font-weight: 600;
      font-size: 30px;
      line-height: 43px;
      text-align: center;
      margin-right: 40px;
      margin-bottom: 40px;
      color: $primary-color;
      margin-top: 42px;
    }

    .ally-feedback-heading {
      font-weight: 600;
      font-size: 30px;
      line-height: 43px;
      margin-left: 100px;
      margin-bottom: 40px;
      color: $primary-color;
      margin-top: 42px;
    }

    .Gaurd {
      margin-top: -3.6rem;
      margin-left: 6rem;
      margin-bottom: 12px;
    }

    .text-heading {
      font-size: 30px;
      font-weight: 600;
      line-height: 42px;
      color: $black-shade;
      text-align: center;
      margin-bottom: 14px;
    }

    .text-detail {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0px;
      text-align: center;
      color: $black-shade;
    }

    .lower-button {
      margin-top: 40px;
    }

    .mr21 {
      margin-right: 21px;
    }
  }

  .lower-button-form {
    margin-top: 40px;
    width: 150px !important;
    height: 52px !important;
  }

  &.form {
    .bad-form-heading {
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 18px;
      color: $black-shade;
      margin-top: 80px;
      max-width: 600px;
    }

    .sec-text-rt {
      margin-left: 25px !important;
    }

    .card {
      border: none;
      box-shadow: 0px 7px 8px $shadow-color;
      max-width: 600px;
    }

    .card-header {
      background-color: $white-shade;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: $black-shade;
      border-bottom: 1px solid $border-text;
    }

    .margins {
      margin-top: 2rem;
    }
  }
}

/* ally css */

.ally-container {
  &.main {
    background-color: $link-color;
    width: 100%;
    height: 100%;

    .allyIcon {
      display: flex;
      justify-content: center;
      margin-bottom: 36px;
      z-index: 999;
    }

    .index {
      z-index: 999;
    }

    .opaque-mask-ally {
      width: 100%;
      position: absolute;
      opacity: 0.95;
      z-index: 1;
      min-height: 100vh;
      background-color: $link-color;
    }

    .quest-completed {
      background-color: $primary-color;
      z-index: 999;
      color: $white-shade;

      &:hover {
        background-color: $primary-color !important;
        color: $white-shade !important;
      }
    }

    .underline {
      position: absolute;
      z-index: 999;
    }

    .top-inner {
      margin-bottom: 10px;
      z-index: 999;
    }

    .middle-inner {
      margin-bottom: 24px;
      z-index: 999;
    }

    .ally-heading {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: $white-shade;
      display: flex;
      justify-content: center;
      z-index: 999;
    }

    .ally-main-heading {
      font-weight: 600;
      font-size: 30px;
      line-height: 43px;
      margin-bottom: 26px;
      margin-top: -39px;
      color: $white-shade;
      z-index: 999;
    }

    .battle-heading {
      font-weight: 600;
      font-size: 22px;
      line-height: 31px;
      color: $white-shade;
      z-index: 999;
    }

    .plike {
      margin-right: 6px;
      z-index: 999;
    }

    .ally-refresh {
      border-radius: 50px;
      position: absolute;
      bottom: -0.5em;
      right: -1em;
      cursor: pointer;
    }

    .avtar-ally {
      position: absolute;
      border: 3px solid #f6f6f6;
      border-radius: 50%;
      background: #f6f6f6;
      height: 76px;
      top: -1.5em;
      left: -0.7em;
    }

    .ally-refresh-img {
      // position: absolute;
      // top: 92px;
      // right: 48px;
    }

    .ally-chat {
      margin-left: 32px;
    }

    .chats {
      color: #121212;
      padding: 27px 22px;
      width: 20em;
      padding-left: 60px;
      min-height: 90px;
      background-color: $cancel-shade;
      border-radius: 22px;
    }

    .ally-activated {
      background-color: $white-shade;
      z-index: 999;
      color: $link-color;
      border: none;
      @include media-breakpoint-up(md) {
        & {
          width: 50% !important;
        }
      }
      @include media-breakpoint-up(lg) {
        & {
          width: 40% !important;
        }
      }

      &:hover {
        background-color: $white-shade !important;
        color: $link-color !important;
        border: none;
      }
    }

    .ally-pic {
      border-radius: 50px;
      border: 2px solid #62b3ed;
      margin-top: 30px;
    }

    .lowerally {
      margin-top: 20px;
      z-index: 999;
      color: $white-shade;
      opacity: 0.75;
      padding-bottom: 30px;
      text-align: center;
    }

    .lower-inner {
      margin-bottom: 14px;
      z-index: 999;
    }

    .inner-text-area {
      margin-left: -32px;
    }

    .ally-Arrow {
      margin-right: 20px;
    }

    .inner-battle {
      margin-bottom: 12px;
    }

    &.bg {
      background-image: url("../../assets/power-bg.svg");
      background-size: contain;
      background-position: 0px 350px;
      background-repeat: no-repeat;
      background-origin: content-box;
      background-attachment: fixed;
      min-height: 100vh;

      // @media screen and (max-height: 950px) {
      //     height: 115vh;
      // }

      // @media screen and (max-height: 850px) {
      //     height: 140vh;
      // }

      // @media screen and (max-height: 700px) {
      //     height: 170vh;
      // }
    }

    &.feedback {
      .bad-feedback-heading {
        font-weight: 600;
        font-size: 30px;
        line-height: 43px;
        text-align: center;
        margin-right: 40px;
        margin-bottom: 40px;
        color: $primary-color;
        margin-top: 42px;
      }

      .Gaurd {
        margin-top: -3.6rem;
        margin-left: 6rem;
        margin-bottom: 12px;
      }

      .text-heading {
        font-size: 30px;
        font-weight: 600;
        line-height: 42px;
        color: $black-shade;
        text-align: center;
        margin-bottom: 14px;
        padding-left: 60px;
        margin-right: 70px;
      }

      .text-detail {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: center;
        color: $black-shade;
      }

      .lower-button {
        margin-top: 40px;
        margin-right: 12px;
      }
    }

    &.form {
      .bad-form-heading {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 18px;
        color: $black-shade;
        margin-top: 80px;
        max-width: 600px;
      }

      .card {
        border: none;
        box-shadow: 0px 7px 8px $shadow-color;
        max-width: 600px;
      }

      .card-header {
        background-color: $white-shade;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $black-shade;
      }

      .margins {
        margin-top: 2rem;
      }
    }
  }
}

// .dash-b::before {
//   font-size: 1em;
//   color: #fff;
//   content: "—";
//   margin-right: 10px;
//   margin-left: 12px;
// }

// .dash-b {
//   margin-left: 15px;
// }

.bg-ally {
  background-image: url("../../assets/Blue.jpg");
  background-size: contain;
  background-position: 0px 350px;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-attachment: fixed;
  min-height: 100vh;
}

// .scale-down-resilience {
//   transform: scale(0.8);
// }

@keyframes scale-down {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
.scale-down-resilience {
  animation: scale-down 2s ease 0s 1 normal forwards;
}

@keyframes load-bonus {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: scale(1);
  }
}

.show-bonus-resilience {
  animation: load-bonus 2s ease 0s 1 normal forwards;
}

.ally-middle {
  .detail_underline::before {
    content: "";
    color: #1675ba;
    border: 7px solid;
    border-radius: 5px;
    margin-top: -11px;
    width: 100%;
    opacity: 0.2;
    display: inline-block;
    position: absolute;
    bottom: 0;
  }
}
