// utitity to make fontweight custom classes
$root-font-size: 16;
@for $fw-index from 1 to 10 {
  .fw-#{$fw-index} {
    font-weight: (100 * $fw-index);
  }
}

// utitity to make fontsize custom classes
@for $fs-index from 1 to 7 {
  .fs-#{$fs-index} {
    font-size: (0.4rem * $fs-index) !important;
  }
}

// Font Sizes
$i: 8;

@while $i<=166 {
  .fsc-#{$i} {
    font-size: #{$i / $root-font-size}rem !important;
  }

  $i: $i + 1;
}

// utitity to make fontsize custom classes in pixel
@for $fs-index from 1 to 7 {
  .fs-p-#{$fs-index} {
    font-size: (0.4rem * $fs-index) !important;
  }
}

// utitity to make ml mr custom classes
@for $index from 0 to 6 {
  .ml-#{$index} {
    margin-left: (0.25rem * $index) !important;
  }
  .mr-#{$index} {
    margin-right: (0.25rem * $index) !important;
  }
}

// utitity to make width custom classes
@for $index from 1 to 10 {
  .w-#{$index} {
    width: (10% * $index) !important;
  }

  .h-#{$index} {
    height: (10% * $index) !important;
  }
}

// mixin to make image round
@mixin round($size) {
  width: $size;
  height: $size;
  border-radius: 100%;
}

// alignment
@each $alignment in $alignments {
  .text-#{$alignment} {
    text-align: unquote($alignment);
  }
}
// text-truncate
@mixin line-clamp($number) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $number;
  -webkit-box-orient: vertical;
}

.d-contents {
  display: contents !important;
}
