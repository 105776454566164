.soloPlay {
  &.form {
    .heading {
      font-weight: 600;
      font-size: 26px;
      line-height: 43px;
      margin-left: 140px;
      margin-bottom: 40px;
      color: $primary-color;
      margin-top: 42px;
    }

    .head2 {
      font-weight: 600;
      font-size: 26px;
      line-height: 43px;
      margin-bottom: 40px;
      color: $black-shade;
      margin-top: 42px;
    }

    .pic {
      margin-top: 20px;
      height: 75px;
      width: 75px;
      border-radius: 50px;
      margin-left: 30px;
    }

    .uploading {
      position: relative;
      top: 65px;
      right: 20px;
    }

    .text-area-input {
      min-height: 10rem;
      border-color: $border-text;
      resize: none;
    }

    .input-title {
      border-color: $border-text;
    }

    .lower-button {
      margin-top: 40px;
      margin-right: 12px;
    }
  }

  .horizontal-slider {
    width: 100%;
    max-width: 500px;
    height: 30px;
    border: none;
  }

  .example-thumb {
    font-size: 0.9em;
    text-align: center;
    background-color: $white-shade;
    color: $white-shade;
    cursor: pointer;
    height: 20px;
    border: 6px solid $primary-color;
    box-sizing: border-box;
    border-radius: 10rem;
    &.example-thumb-0 {
      &.active {
        border: 6px solid $primary-color !important;
        outline: none;
      }
    }
  }

  .example-track {
    position: relative;
    background: $primary-color;
  }

  .example-track.example-track-1 {
    background: transparent;
  }

  .horizontal-slider .example-track {
    top: 5px;
    height: 4px;
  }

  .horizontal-slider .example-thumb {
    top: -3px;
    left: 20px;
    width: 20px;
    height: 20px;
    line-height: 10px;
  }

  .divider {
    opacity: 0.1;
    border: 1px solid #121212;
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .back-button {
    margin-top: 19px;
    margin-bottom: 50px;
    background-color: $cancel-shade;
    width: 116px;
    color: $black-shade;
    border: none;
    &:hover {
      background-color: $cancel-shade !important;
      border: none;
    }
  }
}

.activity-add {
  width: 190px;
  height: 200px;
  background: #ffffff;
  border: 1px dashed rgba(18, 18, 18, 0.2);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: dashed 4px #000;
  border-image-source: url("../../assets/border-dashed.png");
  border-image-slice: 3;
  border-image-repeat: round;
  opacity: 0.7;
}
