// theme color definitions
$primary-color: #4a60ab;
$primary-color-1: #309dec;

$secondary-color: #f99f22;
$secondary-color-opaque: rgba(249, 159, 34, 0.5);

$border-text: #ebebeb;
$text-color: #888888;
$dark-color: #444444;
$link-color: #45a2e5;
$light-gray-shade: #f9f9f9;
$gray-1: #949699;
$palette-shade: #e3e3e3;
$history-txt: #717171;

$white-shade: white;
$white-shade-1: #f4f4f4;
$black-title-shade: #222222;
$primary-color-dark: #2a3661;
$shadow-color: rgba(0, 0, 0, 0.04);
$green-shade: #99c815;
$yellow-shade: #fdcb1e;
$orange-shade: #fad499;
$orange-shade-text: #e9a02e;
$avatar-border: #f5ab38;
$black-shade: #121212;
$yellow-dark-shade: #e9b502;
$yellow-botton-text: #abc724;
$yellow-light-shade: #e8f7a3;
$purple-shade: #9146a4;
$primary-color-light: #d0dbff;
$purple-dark-shade: #8b3b9f;
$very-dark-purple: #3a0049;
$purple-border-shade: #112c86;
$purple-light-shade: #8199e9;
$dark-purple-color: #420a51;
$gray-shade: #ced4da;
$cancel-shade: #eeeeee;
$brown-shade: #333333;
$green-shade: #5bc193;
$light-skin: #f5f5f5;
$light-prefrence: #f9f9f9;
$epic-win: #595959;
$heart-text: #c02d62;
$bg-heart: #ff9cbf;
$phy-text: #e59311;
$phy-guard: #fad499;
$bonus-guard: #e799fa;

// adornments
$adornments: "search", "password";

// alignments
$alignments: "center", "left", "right";
