/*auth page*/
@include media-breakpoint-up(md) {
  .auth-content-container {
    max-width: 480px;
  }

  .w-8 {
    width: 100% !important;
  }

  .w-6 {
    width: 80% !important;
  }

  .user-card {
    width: 100% !important;
  }

  /*cards*/
  .active-cl {
    max-width: 100% !important;
  }

  /*Discover filter*/
  .filter-cl {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .filters-discover {
    .dropdown-toggle {
      margin-right: 0px;
    }
  }

  .top-corner-notes {
    display: flex;
    justify-content: right;
    padding-top: 40px;
    padding-bottom: 24px;
    padding-right: 35px;
    z-index: 999;
  }

  .top-corner-button {
    display: flex;
    justify-content: right;
    padding-right: 35px;
    z-index: 999;
  }

  .middle-activity-sec {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 694px;
    z-index: 999;
  }

  .feedbacks-heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 43px;
    text-align: center;
    margin-left: 10px;
    margin-bottom: 40px;
    color: $black-shade;
    margin-top: 42px;
  }

  .feedbacks-heading-ally {
    font-weight: 600;
    font-size: 30px;
    line-height: 43px;
    text-align: center;
    margin-right: 30px;
    margin-bottom: 40px;
    color: $black-shade;
    margin-top: 42px;
  }

  .wd140 {
    width: 190px;
  }

  .wd79 {
    width: 194px;
  }

  .more-chl-container {
    justify-content: center !important;
  }

  .mr45 {
    margin-right: 0px;
  }

  .m-top {
    margin-top: 40px;
  }
  .h-ta {
    min-height: 100vh;
  }
  .mr-yy {
    margin-left: 70px;
  }
}

@include media-breakpoint-up(lg) {
  .auth-content-container {
    max-width: 480px;
  }

  .w-8 {
    width: 100% !important;
  }

  .w-6 {
    width: 80% !important;
  }

  /*cards*/
  .active-cl {
    max-width: 100% !important;
  }

  /*Discover filter*/
  .filter-cl {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .filters-discover {
    .dropdown-toggle {
      margin-right: 0px;
    }
  }

  .top-corner-notes {
    display: flex;
    justify-content: right;
    padding-top: 40px;
    padding-bottom: 24px;
    padding-right: 35px;
    z-index: 999;
  }

  .top-corner-button {
    display: flex;
    justify-content: right;
    padding-right: 35px;
    z-index: 999;
  }

  .middle-activity-sec {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 694px;
    z-index: 999;
  }

  .feedbacks-heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 43px;
    text-align: center;
    margin-right: 35px;
    margin-bottom: 40px;
    color: $black-shade;
    margin-top: 42px;
  }

  .feedbacks-heading-ally {
    margin-right: 10px;
  }

  .wd140 {
    width: 190px;
  }

  .wd79 {
    width: 194px;
  }

  .more-chl-container {
    justify-content: start !important;
  }

  .mr45 {
    margin-right: 22px;
  }

  .m-top {
    margin-top: 40px;
  }
  .h-ta {
    min-height: 100vh;
  }
  .mr-yy {
    margin-left: 0px;
  }

  .hover-container {
    .hover-icon {
      display: none;
    }

    &:hover {
      .hover-icon {
        display: inline-flex;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .w-8 {
    width: 80% !important;
  }

  .w-6 {
    width: 60% !important;
  }

  .active-cl {
    max-width: 600px !important;
  }

  /*Discover filter*/
  .filter-cl {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filters-discover {
    .dropdown-toggle {
      margin-right: 25px;
    }
  }

  .top-corner-notes {
    display: flex;
    justify-content: right;
    padding-top: 40px;
    padding-bottom: 24px;
    padding-right: 150px;
    z-index: 999;
  }

  .top-corner-button {
    display: flex;
    justify-content: right;
    padding-right: 150px;
    z-index: 999;
  }

  .middle-activity-sec {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 768px;
    z-index: 999;
  }

  .feedbacks-heading {
    font-weight: 600;
    font-size: 30px;
    line-height: 43px;
    text-align: center;
    margin-right: 30px;
    margin-bottom: 40px;
    color: $black-shade;
    margin-top: 42px;
  }

  .feedbacks-heading-ally {
    margin-right: 30px;
  }

  .wd140 {
    width: 190px;
  }

  .wd79 {
    width: 79px;
  }

  .more-chl-container {
    justify-content: start !important;
  }

  .m-top {
    margin-top: 0px;
  }
  .h-ta {
    min-height: 100vh;
  }
  .mr-yy {
    margin-left: -30px;
  }
}
