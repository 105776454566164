.invalid-feedback {
  display: block;
}

[id*="-adornment"] {
  cursor: pointer;
  background-color: $white-shade;
  border-width: 1px 1px 1px 0px;
}

.form-control {
  padding: 0.75rem 0.75rem !important;
  border: 1px solid #ebebeb;
}
.form-select {
  padding: 0.775rem 2.25rem 0.775rem 0.75rem;
  border: 1px solid #ebebeb;
}
.form-label {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
}
.form-control:focus {
  border: 1px solid #ebebeb;
  box-shadow: none;
}

input[name*="password"],
input[name*="search"] {
  border-right: none;

  &:focus {
    box-shadow: none;
    border: 1px solid #ebebeb;
    border-right: none;
  }
}

.form-control:disabled {
  background-color: transparent;
}

input[name*="fullname"],
input[name*="email"],
input[name*="birthdate"],
input[name*="timezone"],
input[name*="Newsletter"],
input[name*="specialCode"] {
  &:focus {
    box-shadow: none;
    border: 1px solid $gray-shade;
  }
}

.noFocus {
  &:focus {
    box-shadow: none;
    border: 1px solid $gray-shade;
  }
}
