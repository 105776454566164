.navbar.custom-navbar {
  color: $dark-color;
  background-color: white !important;
  box-shadow: 0px 4px 4px $shadow-color;

  .brand-container {
    align-items: center;
    gap: 1rem;
  }

  .search-container {
    margin-right: 2.5rem;
  }

  img {
    cursor: pointer;
  }

  .input-group-type-search {
    width: 22.5rem;
  }
}

.navbar-logo {
  width: 3rem;
  height: auto;
}

#navbarScroll {
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;

  & > div {
    display: inherit;
  }

  .avatar-img {
    @include round(2.75rem);
  }
}
.navbar-collapse {
  display: flex;
}
