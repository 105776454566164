.datepicker-container {
  .row {
    height: unset;
  }

  .mr-1rem {
    margin-right: 1rem;
  }

  .react-datepicker {
    &__header {
      &__dropdown {
        display: flex;
        justify-content: space-around;
        align-items: center;
        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__year-read-view--down-arrow {
          top: 3px;
          border-color: black;
          border-width: 2px 2px 0 0;
        }
        .react-datepicker__month-read-view--selected-month,
        .react-datepicker__year-read-view--selected-year {
          color: black;
        }
        .react-datepicker__month-option,
        .react-datepicker__year-option {
          color: black;
          &--selected {
            color: black;
          }
        }
      }
    }
  }
}
