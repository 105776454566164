.switch {
  background-color: $border-text;
  border-radius: 3rem;
  justify-content: center;
  width: fit-content;
  margin: 0 auto;
  padding: 5px;
  // padding: 1rem 0;

  .pill-btn {
    background-color: transparent;
    color: $black-title-shade;
    border: none;
    width: 7.6rem;
    height: 2.4rem;

    &:hover {
      background-color: initial !important;
      color: $black-title-shade;
    }

    &.active {
      background-color: $primary-color;
      color: $white-shade;
      &:hover {
        background-color: $primary-color !important;
      }
    }
  }
}
