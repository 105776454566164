.further-goal {
  &main {
    display: flex;
    justify-content: center;
  }

  .further-goal-title {
    color: $black-shade;
  }

  .title-sp {
    display: flex;
    justify-content: center;
    margin-bottom: 36px;
  }
  .text-area {
    width: 80% !important;
  }
  .text-area-input {
    min-height: 10rem;
    border-color: $border-text;
    resize: none;
  }
  .back-button {
    // position: absolute;
    // bottom: 0;
    margin-top: 95px;
    margin-bottom: 85px;
    background-color: $cancel-shade;
    width: 116px;
    color: $black-shade;
    border: none;
    &:hover {
      background-color: $cancel-shade !important;
      border: none;
    }
  }
}

.sg-chal {
  .back-button {
    // position: absolute;
    // bottom: 0;
    margin-top: 95px;
    margin-bottom: 85px;
    background-color: $cancel-shade;
    width: 116px;
    color: $black-shade;
    border: none;
    &:hover {
      background-color: $cancel-shade !important;
      border: none;
    }
  }
  .next-button {
    margin-top: 95px;
    margin-bottom: 85px;
    background-color: $primary-color;
    width: 116px;
    color: $white-shade;
    border: none;
    &:hover {
      background-color: $primary-color !important;
      border: none;
    }
  }

  .colorful-card {
    max-width: 520px !important;
  }
}
