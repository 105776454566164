.activity-card-container {
  width: 190px;
  height: 200px;
  padding: 0;
  position: relative;
  border-radius: 10px;
  border: none;
  text-align: left !important;
  background-image: url("../../assets/activity-card.png");

  .card-body {
    z-index: 2;
    color: $white-shade;

    .quest {
      position: absolute;
      bottom: 0;
      color: $white-shade;
      padding-right: 0.5rem;
    }
  }

  .opaque-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.95;
    border-radius: 10px;
    z-index: 1;
  }

  .title {
    position: absolute;
    top: -3rem;
    left: 0;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: $brown-shade;
  }

  .Cardtitle {
    position: absolute;
    top: -3rem;
    left: 0;
    text-transform: uppercase;
    font-size: 18px !important;
    line-height: 26px;
    letter-spacing: 0.2px;
    text-align: left;
    color: $brown-shade;
    width: 100%;
  }

  .total-count-plus {
    position: absolute;
    top: -3rem;
    right: 2.8rem;
    transform: translateY(0.4rem);
  }

  .total-count {
    position: absolute;
    top: -3rem;
    right: 0.5rem;
    transform: translateY(0.4rem);
  }

  .plus-icon {
    position: absolute;
    top: -2.8rem;
    right: 0.5rem;
  }

  .ac-see {
    position: absolute;
    bottom: -50px;
    right: 0px;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    color: $primary-color;
  }

  .round-btn {
    @include round(3rem);
    background-color: $white-shade;
    box-shadow: 0 0 1rem rgba(255, 255, 255);
    border: none;
    position: absolute;

    &:hover {
      background-color: $white-shade !important;
      border: none;
    }

    &.play {
      top: 1rem;
      right: 1rem;
    }

    &.plus {
      color: $black-title-shade;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.select-challenge-card {
  background: $white-shade;
  border: 1px solid $light-prefrence;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 180px;
  height: 180px;

  .spaces {
    margin-left: 23px;
    margin-right: 23px;
  }
}

.oultline-challenge-card {
  background: $white-shade;
  border: 1px solid $border-text;
  border-radius: 10px;
  width: 210px;

  .spaces {
    margin-left: 23px;
    margin-right: 23px;
  }
}

.act-bg {
  background-image: url("../../assets/act-crd.svg") !important;
}
